<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table style="width: 100%">
                        <tr>
                            <td class="td-text">
                                合同/协议编号：
                            </td>
                            <td style="width: 400px;">
                                <el-input v-model="searchItem.contractNum" size="small" placeholder="请输入合同/协议编号"
                                ></el-input>
                            </td>
                            <td class="td-text">
                                公司名称：
                            </td>
                            <td style="width: 400px;">
                                <el-input v-model="searchItem.companyName" size="small" placeholder="请输入公司名称"
                                ></el-input>
                            </td>
                            <td class="td-text">
                                销售人员：
                            </td>
                            <td style="width: 400px;">
                                <el-select v-model="searchItem.userName" size="small"
                                           style="width: 100%"
                                           filterable
                                           placeholder="请选择销售人员">
                                    <el-option
                                            v-for="item in saleList"
                                            :key="item"
                                            :label="item"
                                            :value="item">
                                    </el-option>
                                </el-select>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-text" style="padding-top: 10px">
                                发货编号：
                            </td>
                            <td style="width: 400px;padding-top: 10px">
                                <el-input v-model="searchItem.sendNoticeNum" size="small" placeholder="请输入发货编号"
                                ></el-input>
                            </td>
                            <td class="td-text" style="padding-top: 10px">
                                公司类型：
                            </td>
                            <td style="width: 400px;padding-top: 10px">
                                <template>
                                    <el-select v-model="searchItem.companyType" style="width: 100%;" size="small"
                                               placeholder="请选择公司类型">
                                        <el-option
                                                v-for="item in unitTypes"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </template>
                            </td>
                            <td class="td-text" style="padding-top: 10px">
                                生成时间：
                            </td>
                            <td style="width: 400px;padding-top: 10px">
                                <el-date-picker
                                        v-model="searchItem.startDate"
                                        type="date"
                                        size="small"
                                        placeholder="选择日期时间"
                                        style="width: 47%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                                至
                                <el-date-picker
                                        v-model="searchItem.endDate"
                                        type="date"
                                        size="small"
                                        placeholder="选择日期时间"
                                        style="width:  47%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: right;padding-top: 10px">项目名称：</td>
                            <td style="padding-top: 10px">
                                <el-input v-model="searchItem.projectName" size="small" placeholder="请输入项目名称"
                                ></el-input>
                            </td>
                            <td style="text-align: right;padding-top: 10px">通知单类型：</td>
                            <td style="width: 400px;padding-top: 10px">
                                <template>
                                    <el-select v-model="searchItem.contractType" style="width: 100%;" size="small"
                                               placeholder="请选择通知单类型">
                                        <el-option
                                                v-for="item in contractType"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </template>
                            </td>
                            <td></td>
                            <!--<td style="text-align: right">
                                <el-button type="primary" icon="el-icon-download" size="small" @click="exportExcel">导出为Excel文件</el-button>
                            </td>-->
                            <td style="padding-top: 10px;text-align: center" colspan="2">
                                <el-button type="primary" icon="el-icon-search" size="small"
                                           @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" style="margin-left: 20%" icon="el-icon-refresh-right"
                                           size="small" @click="resetBtn">重置
                                </el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '45px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: count}">
                            <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
                            <el-table-column prop="contractNum" label="合同/协议编号" width="160" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="contractType" label="通知单类型" width="120" align="center">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.afterSalesId">
                                        <span>售后退换货</span>
                                    </div>
                                    <div v-else>
                                        <div v-if="scope.row.applyType == 0">
                                            <span>试用协议</span>
                                        </div>
                                        <div v-else>
                                            <span v-if="scope.row.contractType == 0">销售合同</span>
                                            <span v-else-if="scope.row.contractType == 1">技术服务合同</span>
                                            <span v-else-if="scope.row.contractType == 2">部件买卖合同</span>
                                            <span v-else-if="scope.row.contractType == 3">售后维修合同</span>
                                        </div> 
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="userName" label="销售人员" width="140" align="center"></el-table-column>
                            <el-table-column prop="sendNoticeNum" label="发货通知单编号" width="180"
                                             align="center"></el-table-column>
                            <el-table-column prop="companyName" label="公司名称" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="companyType" label="公司类型" width="100" align="center">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.companyType == 0">建机厂</span>
                                    <span v-else-if="scope.row.companyType == 1">国企</span>
                                    <span v-else-if="scope.row.companyType == 2">租赁商</span>
                                    <span v-else-if="scope.row.companyType == 3">代理商</span>
                                    <span v-else>集团公司</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="projectName" label="项目名称" width="140" show-overflow-tooltip
                                             align="left">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.projectName == ''">无</span>
                                    <span v-else>{{scope.row.projectName}}</span>
                                </template>
                            </el-table-column>
                            <!--
                                <el-table-column prop="times" label="发货次数" width="100"  align="center"></el-table-column>
                            -->
                            <el-table-column label="发货状态" width="100" align="center">
                                <template slot-scope="scope">
                                    <span type="text" v-if="scope.row.sendTotalCount > 0"
                                          style="color: green">已发货</span>
                                    <span type="text" v-else>未发货</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="sendNoticeTime" label="生成时间" :formatter="dateFormat" width="180"
                                             align="center"></el-table-column>
                            <el-table-column label="发货单" width="180" align="center">
                                <template slot-scope="scope">
									<el-button type="text" v-if="scope.row.contractType == 2" disabled></el-button>
                                    <el-button type="text" v-else-if="scope.row.sendTotalCount == 0" disabled>发货单</el-button>
                                    <el-button type="text" v-else @click="showShippingBill(scope.row.id)">发货单</el-button>
                                </template>
                            </el-table-column>
                            <el-table-column prop="id" label="操作" width="80" align="center">
                                <template slot-scope="scope">
                                    <div class="opertion">
                                        <i class="iconfont iconxiangqing" title="查看详情"
                                           @click="showShipping(scope.row.afterSalesId,scope.row.id,scope.row.sendNoticeNum)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="shipping-dialog">
            <el-dialog
                    title="出库单据详情"
                    :visible.sync="shippingVisible"
                    width="70%"
                    :close-on-click-modal="false"
                    center>
                <el-table
                        :data="shippingData"
                        style="width: 100%;height: 100%;" :border="true">
                    <el-table-column type="index" label="序号" width="50" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="outListNum" label="出库单据" width="150" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="createTime" label="出库时间" width="120" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="sendUser" label="操作人员" width="140" align="center"></el-table-column>
                    <el-table-column prop="sendCount" label="发货数量" width="110" align="center"></el-table-column>
                    <el-table-column label="物料详情" width="140" show-overflow-tooltip align="center">
                        <template slot-scope="scope">
                            <span>SIM卡{{scope.row.sendCount}}张，IMEI型号{{scope.row.sendCount}}个</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="receivingUser" label="发货对象" width="100" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="receivingUserTel" label="收件人电话" width="100" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="receivingAddress" label="发货地址" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <!--  <el-table-column label="发货状态" width="100"  align="center">
                          <template slot-scope="scope">
                              <span v-if="scope.row.status == true" style="color: green">已发货</span>
                              <span v-if="scope.row.status != true" style="color: red">未发货</span>
                          </template>
                      </el-table-column>
                      <el-table-column prop="remark" label="备注"  align="center" show-overflow-tooltip></el-table-column>
                      --></el-table>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="shippingVisible = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="information">
            <el-dialog
                    :title="showTitle"
                    :visible.sync="infoVisible"
                    width="70%"
                    :close-on-click-modal="false"
                    style="margin-top: -90px"
                    center>
                <div class="dialog-body">
                    <div class="basic-info">
                        <div class="text-title">
                            基础信息
                        </div>
                        <div>
                            <table style="text-align: left;width: 100%">
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        公司名称：
                                    </td>
                                    <td style="width: 300px;">
                                        {{shippingForm.companyName}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">合同号：</td>
                                    <td style="width: 170px">
                                        {{shippingForm.contractNum}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">客户类型：</td>
                                    <td style="width: 300px">
                                        {{shippingForm.companyType==0?"建机厂":(shippingForm.companyType==1?"国企":(shippingForm.companyType==2?"租赁商":(shippingForm.companyType==3?"代理商":"集团公司")))}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        通知单时间：
                                    </td>
                                    <td style="width: 300px;">
                                        {{shippingForm.sendNoticeTime}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">塔机数量：</td>
                                    <td style="width: 170px">
                                        {{shippingForm.towerCount}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">升降机数量：</td>
                                    <td style="width: 170px">
                                        {{shippingForm.lifterCount}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        收货人：
                                    </td>
                                    <td style="width: 300px;">
                                        {{shippingForm.receivingUser}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">联系电话：</td>
                                    <td style="width: 170px">
                                        {{shippingForm.receivingUserTel}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">送货地址：</td>
                                    <td style="width: 170px">
                                        {{shippingForm.receivingAddress}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        项目名称：
                                    </td>
                                    <td style="width: 300px;">
                                        {{shippingForm.projectName}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">
                                        服务周期(月)：
                                    </td>
                                    <td style="width: 300px;">
                                        {{shippingForm.startDate}}~{{shippingForm.endDate}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">
                                        设备类型
                                    </td>
                                    <td style="width: 300px;">
                                        {{shippingForm.deviceType==0?'普通型':'加强型'}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        收费政策：
                                    </td>
                                    <td style="width: 300px;" colspan="5">
                                        {{shippingForm.chargePolicy}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        特殊政策及发货说明：
                                    </td>
                                    <td style="width: 300px;" colspan="5">
                                        {{shippingForm.specialPolicy}}
                                    </td>
                                </tr>
                                <tr v-if="shippingForm.contractType !=2">
                                    <td style="width: 92px">功能需求：</td>
                                    <td colspan="5">
                                        <div class="td-body">
                                            <table cellpadding="0" cellspacing="0" style="width: 100%">
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        序号
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in towerItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.index}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;text-overflow:ellipsis;padding: 3px; text-align: center">
                                                        塔机
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in towerItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in towerItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        升降机
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in lifterItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in lifterItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        特殊型号
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in specialItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in specialItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td>部件需求：</td>
                                    <td colspan="5" style="padding: 10px 0">
                                        <div class="td-body">
                                            <table cellpadding="0" cellspacing="0" style="width: 100%">
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        部件名称
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in componentItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in componentItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <template>
                                <el-table
                                        :data="basicTable"
                                        border
                                        style="margin-top: 10px;width: 100%"
                                        :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                        :cell-style="{padding:'0',height: '41px'}">
                                    <el-table-column type="index" label="序号" width="50px"
                                                     align="center"></el-table-column>
                                    <el-table-column prop="deviceCategory" label="设备类型" align="center">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.deviceCategory == 1">升降机</span>
                                            <span v-else>塔机</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="sizeModel" label="规格型号" align="center"></el-table-column>
                                    <el-table-column prop="siteNum" label="现场编码" align="center"></el-table-column>
                                    <el-table-column prop="stringDiameter" label="钢丝绳直径(mm)"
                                                     align="center"></el-table-column>
                                    <el-table-column prop="armLength" label="前臂长度(m)" align="center"></el-table-column>
									<el-table-column prop="rearArmLength" label="后臂长度(m)" align="center"></el-table-column>
                                    <el-table-column prop="maxLoad" label="最大载重(T)" align="center"></el-table-column>
                                    <el-table-column prop="deviceType" label="类型" align="center"></el-table-column>
                                    <el-table-column prop="isNeedCard" label="是否需要视频流量卡" align="center">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.isNeedCard == 1">是</span>
                                            <span v-else>否</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="isNeedModel" align="center" label="是否需要现场调试多塔防碰撞功能">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.isNeedModel == 1">是</span>
                                            <span v-else>否</span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </div>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="infoVisible = false">确 定</el-button>
                    <el-button type="primary" icon="el-icon-printer" @click="printData">打 印</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="information">
            <el-dialog
                    :title="showAfterTitle"
                    :visible.sync="infoAftermarketVisible"
                    width="70%"
                    :close-on-click-modal="false"
                    style="margin-top: -90px"
                    center>
                <div class="dialog-body">
                    <table width="100%">
                        <tr>
                            <td width="100px">合同号：</td>
                            <td colspan="3">{{aftermarketForm.contractNum}}</td>
                            <td width="100px">申请单编号：</td>
                            <td>{{aftermarketForm.applyNum}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top: 10px" width="100px">客户名称：</td>
                            <td style="padding-top: 10px" width="25%">{{aftermarketForm.companyName}}</td>
                            <td style="padding-top: 10px" width="100px">申请人：</td>
                            <td style="padding-top: 10px">{{aftermarketForm.applyUserName}}</td>
                            <td style="padding-top: 10px" width="100px">现场处理：</td>
                            <td style="padding-top: 10px">{{aftermarketForm.siteUserName}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top: 10px" width="100px">是否在保质期：</td>
                            <td style="padding-top: 10px">{{aftermarketForm.isInWarranty}}</td>
                            <td style="padding-top: 10px" width="100px">售后更换方式：</td>
                            <td style="padding-top: 10px">{{aftermarketForm.changeMethod}}</td>
                            <td style="padding-top: 10px" width="100px">现场处理结果：</td>
                            <td style="padding-top: 10px">{{aftermarketForm.remoteDealResult}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top: 10px" width="100px">工程师：</td>
                            <td style="padding-top: 10px">{{aftermarketForm.remoteDealUserName}}</td>
                            <td style="padding-top: 10px" width="100px">时间：</td>
                            <td style="padding-top: 10px">{{changeDateFormat(aftermarketForm.dealDate)}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top: 10px" width="100px">发货信息：</td>
                            <td style="padding-top: 10px" colspan="5">{{aftermarketForm.logistic}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top: 10px" width="100px">故障描述：</td>
                            <td style="padding-top: 10px" colspan="5">{{aftermarketForm.trouble}}</td>
                        </tr>
                        <tr>
                            <td style="padding-top: 10px" width="100px">备注：</td>
                            <td colspan="5">{{aftermarketForm.remark}}</td>
                        </tr>
                    </table>
                    <div style="margin-top: 10px">
                        <el-table
                                ref="multipleTable"
                                :data="aftermarketForm.materialList"
                                tooltip-effect="dark"
                                style="width: 99%"
                                max-height="350"
                                border
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}"
                        >
                            <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
                            <el-table-column prop="materialCode" label="物料编码" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="materialName" label="产品名称" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="useTypeName" label="适用机型" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="componentName" label="部件名称" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="model" label="型号" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="count" label="数量" align="center"
                                             show-overflow-tooltip></el-table-column>
                        </el-table>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="infoAftermarketVisible = false">确 定</el-button>
                    <el-button type="primary" icon="el-icon-printer" @click="printAftermarketData">打 印</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="export">
            <el-dialog
                    title="审批记录"
                    :visible.sync="exportDialogVisible"
                    :close-on-click-modal="false"
                    width="52.6%" center>
                <download-excel
                        class="export-excel-wrapper"
                        :data="json_data"
                        :fields="json_fields"
                        :name="fileName">
                    <el-table
                            :data="tableData"
                            max-height="400px"
                            size="small"
                            row-class-name="row"
                            cell-class-name="column"
                            :highlight-current-row="true"
                            fit>
                        <el-table-column
                                v-for="(item, index) in jsonFields"
                                :key="index"
                                :prop="item.prop"
                                :width="item.width"
                                :label="item.label" show-overflow-tooltip>
                        </el-table-column>
                    </el-table>
                    <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
                    <el-button type="primary" size="small" style="margin-left: 48%;margin-top: 1%"
                               @click="exportDialogVisible=false">导出数据
                    </el-button>
                </download-excel>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import shipping from '../../../public/json/shippingAsk.json'
    import basicData from '../../../public/json/basicData.json'
    import shippingData from '../../../public/json/shipping.json'
    import contract from "../../../public/json/contract.json";
    import {getChargingDevicesHistory} from "../../assets/api";

    var qs = require('qs');

    export default {
        data() {
            return {
                pageList: [14, 20, 30, 50],
                tableHeight: '',
                showTitle: '',
                lineHeight: '41px',
                jsonFields: [
                    /* {label: '', width: '40', prop: 'id'},*/
                    {label: '合同/协议编号', width: '120', prop: 'contractNum'},
                    {label: '通知单类型', width: '120', prop: 'contractType'},
                    {label: '销售人员', width: '120', prop: 'salesman'},
                    {label: '发货通知单编号', width: '140', prop: 'number'},
                    {label: '公司名称', width: '120', prop: 'companyName'},
                    {label: '公司类型', width: '120', prop: 'unitType'},
                    {label: '生成时间', width: '120', prop: 'produceTime'}
                ],
                fileName: '',
                fields: [],
                values: [],
                json_fields: {},
                json_data: [],
                json_meta: [
                    [
                        {
                            " key ": " charset ",
                            " value ": " utf- 8 "
                        }
                    ]
                ],
                exportDialogVisible: false,
                infoAftermarketVisible: false,
                multipleSelection: [],
                tableData: [],
                basicTable: [],
                shippingData: [],
                towerItems: [],
                lifterItems: [],
                componentItems: [],
                specialItems: [],
                shippingForm: {
                    chargePolicy: "",
                    companyName: null,
                    companyType: null,
                    contractName: "",
                    contractNum: "",
                    lifterCount: "",
                    projectName: "",
                    sendNoticeTime: "",
                    sendTotalCount: "",
                    receivingUser: '',
                    receivingUserTel: '',
                    receivingAddress: '',
                    startDate: "",
                    endDate: "",
                    specialPolicy: "",
                    towerCount: "",
                    updateUserName: "",
                    deviceListStr: [],
                    remarkList: [],
                    towerFunList: '',
                    afterSalasId: '',
                    lifterFunList: '',
                    componentFunList: '',
                    remark: '',
                },
                aftermarketForm: {
                    sendNoticeNum:'',
                    contractNum: '',
                    applyNum: '',
                    companyName: '',
                    applyUserName: '',
                    contact: '',
                    contactTel: '',
                    projectAddress: '',
                    changeMethod: '',
                    siteUserName: '',
                    trouble: '',
                    remoteDealResult: '',
                    remoteDealUserName: '',
                    isInWarranty: '',
                    dealDate: '',
                    logistic: '',
                    materialListStr: '',
                    materialList: [],
                    remark: '',
                },
                saleList: [],
                contractType:[
                    {
                        label: '销售合同',
                        value: 0
                    },
                    {
                        label: '技术服务合同',
                        value: 1
                    },
                    {
                        label: '部件买卖合同',
                        value: 2
                    },
                    {
                        label: '售后合同',
                        value: -1
                    },
                    {
                        label: '试用协议',
                        value: -2
                    },
                ],
                searchItem: {
                    applyType:'',
                    afterSalesId:'',
                    contractType:'',
                    contractNum: '',
                    companyName: '',
                    userName: '',
                    sendNoticeNum: '',
                    projectName: '',
                    companyType: '',
                    startDate: '',
                    endDate: '',
                    pageSize: 14,
                    pageIndex: 1
                },
                unitTypes: [
                    {
                        label: '建机厂',
                        value: 0
                    },
                    {
                        label: '国企',
                        value: 1
                    },
                    {
                        label: '租赁商',
                        value: 2
                    },
                    {
                        label: '代理商',
                        value: 3
                    },
                    {
                        label: '集团公司',
                        value: 4
                    },
                ],
                delList: [],
                infoVisible: false,
                shippingVisible: false,
                // totalPage:0,
                totalPage: 30,
                option: {
                    pageIndex: '',
                    pageSize: 14,
                    currentPage: 2
                },
                count: '',
                showAfterTitle: ''
            }
        },
        methods: {
            resetBtn() {
                this.searchItem = {
                    applyType:'',
                    afterSalesId:'',
                    contractType:'',
                    contractNum: '',
                    companyName: '',
                    userName: '',
                    sendNoticeNum: '',
                    projectName: '',
                    companyType: '',
                    startDate: '',
                    endDate: '',
                    pageSize: 14,
                    pageIndex: 1
                }
                this.pageChange(1)
            },
            pageChange(option) {
                this.option.pageIndex = option;
                this.searchItem.pageIndex = option;
                var postContractType = ''
                var postAfterSalesId = ''
                var postApplyType = ''
                if(this.searchItem.contractType === -1){//售后
                    postContractType = ''
                    postAfterSalesId = 1
                    postApplyType = ''
                }else if(this.searchItem.contractType === -2){//试用协议
                    postContractType = ''
                    postAfterSalesId = ''
                    postApplyType = 0
                }else{
                    postContractType = this.searchItem.contractType
                    postAfterSalesId = ''
                    postApplyType = ''
                }
                var option = {...this.searchItem}
                option.contractType = postContractType
                option.afterSalesId = postAfterSalesId
                option.applyType = postApplyType
                this.$api.pageShipping(option).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            pageSizeChange(val) {
                this.searchItem.pageSize = val;
                var postContractType = ''
                var postAfterSalesId = ''
                var postApplyType = ''
                if(this.searchItem.contractType === -1){//售后
                    postContractType = ''
                    postAfterSalesId = 1
                    postApplyType = ''
                }else if(this.searchItem.contractType === -2){//试用协议
                    postContractType = ''
                    postAfterSalesId = ''
                    postApplyType = 0
                }else{
                    postContractType = this.searchItem.contractType
                    postAfterSalesId = ''
                    postApplyType = ''
                }
                var option = {...this.searchItem}
                option.contractType = postContractType
                option.afterSalesId = postAfterSalesId
                option.applyType = postApplyType
                this.$api.pageShipping(option).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            exportExcel() {
                this.exportDialogVisible = true;
                this.fileName = "发货通知单";
            },
            searchBtn() {
                this.pageChange(1)
            },
            showShipping(afterSalesId, contractID,sendNoticeNum) {
                if (afterSalesId) {
                    this.getDetailAftermarket(afterSalesId,sendNoticeNum);
                } else {
                    this.getDetailContract(contractID);
                }
            },
            getDetailAftermarket(id,sendNoticeNum) {
                this.$api.getAftermarketById({id: id}).then(res => {
                    this.infoAftermarketVisible = true;
                    if (res.code == 200) {
                        this.aftermarketForm = {
                            sendNoticeNum: sendNoticeNum,
                            contractNum: res.data.contractNum,
                            applyNum: res.data.applyNum,
                            companyName: res.data.companyName,
                            applyUserName: res.data.applyUserName,
                            contact: res.data.contact,
                            contactTel: res.data.contactTel,
                            projectAddress: res.data.projectAddress,
                            changeMethod: res.data.changeMethod,
                            siteUserName: res.data.siteUserName,
                            trouble: res.data.trouble,
                            remoteDealResult: res.data.remoteDealResult,
                            remoteDealUserName: res.data.remoteDealUserName,
                            isInWarranty: res.data.isInWarranty,
                            dealDate: res.data.dealDate,
                            logistic: res.data.logistic,
                            materialListStr: JSON.stringify(res.data.materialList),
                            materialList: res.data.materialList,
                            remark: res.data.remark,
                            imeiList:[...res.data.devicInfoeList]
                        }
                        this.showAfterTitle = '《' + res.data.applyNum + '》发货通知单详情';
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            changeDateFormat(date) {
                if (date == null || date == '' || date == undefined) {
                    return '';
                } else {
                    let dt = new Date(date)
                    var year = dt.getFullYear();
                    var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                    var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                    return year + '-' + month + '-' + date
                }
            },
            getDetailContract(id) {
                this.towerItems = [];
                this.lifterItems = [];
                this.componentItems = [];
                this.specialItems = []
                this.$api.getShippingById({id: id}).then(
                    res => {
                        if (res.code == 200) {
                            this.shippingForm = {
                                receivingAddress: res.data.deviceList[0].receivingAddress,
                                receivingUser: res.data.deviceList[0].receivingUser,
                                receivingUserTel: res.data.deviceList[0].receivingUserTel,
                                chargePolicy: res.data.chargePolicy,
                                companyName: res.data.companyName,
                                companyType: res.data.companyType,
                                contractType:res.data.contractType,
                                contractName: res.data.contractName,
                                contractNum: res.data.contractNum,
                                startDate: res.data.startDate,
                                endDate: res.data.endDate,
                                deviceType: res.data.deviceType,
                                lifterCount: res.data.lifterCount,
                                projectName: res.data.projectName,
                                sendNoticeTime: res.data.sendNoticeTime,
                                sendNoticeNum: res.data.sendNoticeNum,
                                sendTotalCount: res.data.sendTotalCount,
                                specialPolicy: res.data.specialPolicy,
                                towerCount: res.data.towerCount,
                                userName: res.data.userName,
                                remark: res.data.remark,
                                remarkList: res.data.auditList,
                                afterSalasId: res.data.afterSalasId,
                                deviceListStr: res.data.deviceList,
                                specialMap: res.data.specialMap,
                                afterSalesId: res.data.afterSalesId,
                                applyType:res.data.applyType,
                            };
                            this.showTitle = '《' + res.data.sendNoticeNum + '》通知单详情'
                            this.basicTable = res.data.deviceList
                            var towerItemsStr = res.data.towerMap;
                            if (towerItemsStr) {
                                var funcItem = {};
                                var index = 1
                                for (var i in towerItemsStr) {
                                    funcItem = {text: '', quality: 0}
                                    funcItem.text = i;
                                    funcItem.index = index;
                                    funcItem.quality = towerItemsStr[i];
                                    this.towerItems.push(funcItem);
                                    index = index + 1
                                }
                            }
                            var lifterItemsStr = res.data.lifterMap;
                            if (lifterItemsStr) {
                                var funcItem = {};
                                for (var i in lifterItemsStr) {
                                    funcItem = {text: '', quality: 0}
                                    funcItem.text = i;
                                    funcItem.quality = lifterItemsStr[i];
                                    this.lifterItems.push(funcItem);
                                }
                            }
                            var specialItemsStr = res.data.specialMap;
                            if (specialItemsStr) {
                                var funcItem = {};
                                for (var i in specialItemsStr) {
                                    funcItem = {text: '', quality: 0}
                                    funcItem.text = i;
                                    funcItem.quality = specialItemsStr[i];
                                    this.specialItems.push(funcItem);
                                }
                            }
                        var componentItemsStr = res.data.componentMap;
                        if (componentItemsStr) {
                            var funcItem = {};
                            for (var i in componentItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text =  i;
                                funcItem.quality = componentItemsStr[i];
                                this.componentItems.push(funcItem);
                            }
                        }
                            this.shippingForm.towerFunList = res.data.towerMap;
                            this.shippingForm.lifterFunList = res.data.lifterMap;
                            this.shippingForm.componentFunList = res.data.componentMap;
                            this.infoVisible = true;
                        }
                    }
                )
            },
            dateFormat(row, column) {
                let data = row[column.property]
                let dt = new Date(data)
                var year = dt.getFullYear();
                var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                return year + '-' + month + '-' + date
            },
            showShippingBill(id) {
                this.shippingVisible = true;
                this.$api.getContractShipping({id: id}).then(res => {
                    if (res.code == 200) {
                        this.shippingData = res.data;
                    }
                })
            },
            printData() {
                this.infoVisible = false;
                if(this.shippingForm.contractType != 2){
                    localStorage.setItem("shippingData", qs.stringify(this.shippingForm))
                    window.open('/printShipping')
                }else{
                    localStorage.setItem("shippingData", qs.stringify(this.shippingForm))
                    window.open('/componentPrint')
                }
            },
            printAftermarketData() {
                this.infoAftermarketVisible = false;
                localStorage.setItem("shippingData", qs.stringify(this.aftermarketForm))
                window.open('/printAftermarket')
            }
        },
        created() {
            this.searchBtn();
            this.$api.getSaleList().then(res => {
                if (res.code == 200) {
                    this.saleList = res.data
                }
            })
            this.tableHeight = (window.innerHeight) * 0.67
            this.shippingData = shippingData.data
            this.basicTable = basicData.data
            if (window.screen.height > 900) {
                this.count = window.screen.height * 0.038
            } else {
                this.count = window.screen.height * 0.033
            }
        }
    }
</script>

<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 20px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 1px;
        display: block;
        cursor: pointer;
    }

    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 18%;
        margin-top: 10px;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .text-title {
        font-size: 16px;
        font-weight: bold;
        color: #437EE6;
        text-align: center;
        margin-bottom: 20px;
    }

    .dialog-body {
        max-height: 650px;
        overflow-y: scroll;
    }

    .td-body {
        padding: 10px 0;
        white-space: nowrap;
        overflow-x: scroll;
        width: 1100px;
    }

    td {
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }

    .td-text {
        width: 90px;
        text-align: right;
    }

    .td-input {
        width: 28%;
    }

</style>
